/* eslint-disable no-unused-vars */
import { IStatusGroups } from "../types/documents";
import { convertGroupedArraysOfObjectsToDict } from "../utils/data";

export const DEFAULT_PAID_RANGE: [number, number] = [0, 20000];
export const DEFAULT_LAST_SUBMITTED_RANGE: [string, string] = ["", ""];

export const COLUMNS_FOR_VISIBILITY_CHANGE = [{
    id: "subStatus",
    title: "Sub-status",
}];

const greyStatus = {
    font: "#555",
    background: "#F6F6F6",
};

const greenStatus = {
    font: "#2EBB49",
    background: "#ECFFEC",
};

const redStatus = {
    font: "#EC0000",
    background: "#FFDFDF",
};

const orangeStatus = {
    font: "#DA6900",
    background: "#FFE6C8",
};

const blueStatus = {
    font: "#1253FA",
    background: "#EDF8FF",
};

const purpleStatus = {
    font: "#540087",
    background: "#F3DFFF",
};

export const GROUPED_STATUSES: IStatusGroups = {
    "new-clients": [
        {
            id: "awaiting-agent-link",
            title: "Awaiting agent link creation",
            color: greyStatus,
            isError: false,
        },
        {
            id: "agent-link-submitted",
            title: "Agent link submitted",
            color: redStatus,
            isError: false,
        },
    ],
    "new-submissions": [
        {
            id: "ready-for-data-scrape",
            title: "Ready for data scrape",
            color: greyStatus,
            isError: false,
        },
        {
            id: "data-scraper-failed",
            title: "Failed to scrape",
            color: greenStatus,
            isError: true,
        },
    ],
    "ready-for-review": [
        {
            id: "paid-payment-confirmation",
            title: "Paid: Ready for Review",
            color: redStatus,
            isError: false,
        },
        {
            id: "ready-for-review",
            title: "Ready for Review",
            color: redStatus,
            isError: false,
        },
        {
            id: "paye-registration-successful",
            title: "PAYE Registration - Successful",
            color: purpleStatus,
            isError: false,
            hideFromSelector: true,
        },
        {
            id: "awaiting-auto-submission",
            title: "Awaiting autosubmission",
            color: greyStatus,
            isError: false,
        },
        {
            id: "auto-submission-failed",
            title: "Autosubmission Failed",
            color: greenStatus,
            isError: true,
        },
    ],
    "submitted-to-revenue": [
        {
            id: "submitted-to-revenue",
            title: "Submitted to Revenue",
            color: orangeStatus,
            isError: false,
        },
    ],
    "payments": [
        {
            id: "awaiting-payment",
            title: "Awaiting Payment",
            color: blueStatus,
            isError: false,
        },
        {
            id: "awaiting-client-bank-details",
            title: "Awaiting Client Bank Details",
            color: blueStatus,
            isError: false,
        },
        {
            id: "bank-details-requested",
            title: "Bank Details Requested",
            color: blueStatus,
            isError: false,
        },
        {
            id: "awaiting-bank-details-confirm",
            title: "Awaiting Bank Details Confirmation",
            color: blueStatus,
            isError: false,
        },
        {
            id: "ready-for-transfer-express",
            title: "Ready to Transfer (Express)",
            color: blueStatus,
            isError: false,
        },
        {
            id: "ready-for-transfer-standard",
            title: "Ready to Transfer (Standard)",
            color: blueStatus,
            isError: false,
        },
        {
            id: "ready-for-transfer-cheque",
            title: "Ready to Transfer (Cheque)",
            color: blueStatus,
            isError: false,
        },
        {
            id: "ready-for-transfer-one4all",
            title: "Ready to Transfer (One4All)",
            color: blueStatus,
            isError: false,
        },
        {
            id: "ready-for-transfer-failed",
            title: "Failed to set Ready to Transfer",
            color: greenStatus,
            isError: true,
        },
    ],
    "id-verification": [
        {
            id: "awaiting-id-verification",
            title: "ID Verification: Awaiting Submission",
            color: greenStatus,
            isError: true,
        },
        {
            id: "id-verification-fraud-check",
            title: "ID Verification: Fraud Check",
            color: redStatus,
            isError: true,
        },
        {
            id: "id-verification-failed",
            title: "ID Verification: Failed",
            color: redStatus,
            isError: true,
        },
        {
            id: "id-verification-time-expired",
            title: "ID Verification: Time Expired",
            color: redStatus,
            isError: true,
        },
    ],
    "archived": [
        {
            id: "archived-submitted-to-revenue",
            title: "Archived - Submitted to Revenue",
            color: greenStatus,
            isError: true,
        },
        {
            id: "nothing-to-submit",
            title: "Nothing to Submit",
            color: greenStatus,
            isError: true,
        },
        {
            id: "payment-complete",
            title: "Payment Complete",
            color: greenStatus,
            isError: true,
        },
        {
            id: "clients-owes-revenue",
            title: "Client Owes Revenue",
            color: greenStatus,
            isError: true,
        },
        {
            id: "agent-link-removed",
            title: "Agent Link Removed",
            color: greenStatus,
            isError: true,
        },
        {
            id: "income-tax",
            title: "Income Tax",
            color: greenStatus,
            isError: true,
        },
        {
            id: "not-assessable-spouse",
            title: "Not Assessable Spouse",
            color: greenStatus,
            isError: true,
        },
        {
            id: "help-to-buy",
            title: "Help to Buy",
            color: greenStatus,
            isError: true,
        },
        {
            id: "proprietary-director",
            title: "Proprietary Director",
            color: greenStatus,
            isError: true,
        },
        {
            id: "autoclosed",
            title: "Autoclosed",
            color: redStatus,
            isError: false,
        },
    ],
    "on-hold": [
        {
            id: "name-error",
            title: "Name Error",
            color: greenStatus,
            isError: true,
        },
        {
            id: "ppsn-error",
            title: "PPSN Error",
            color: greenStatus,
            isError: true,
        },
        {
            id: "below-min-cheque-fee",
            title: "Below min cheque fee",
            color: greenStatus,
            isError: true,
        },
        {
            id: "info-requested",
            title: "Info Requested",
            color: greenStatus,
            isError: true,
        },
        {
            id: "awaiting-spouse-signup",
            title: "Awaiting Spouse Signup",
            color: greenStatus,
            isError: true,
            hideFromSelector: true,
        },
        {
            id: "spouse-requested",
            title: "Spouse Requested",
            color: greenStatus,
            isError: true,
        },
        {
            id: "awaiting-spouse-registration",
            title: "Awaiting Spouse Registration",
            color: greenStatus,
            isError: true,
            hideFromSelector: true,
        },
        {
            id: "awaiting-spouse-confirmation",
            title: "Awaiting Spouse Confirmation",
            color: greenStatus,
            isError: true,
            hideFromSelector: true,
        },
        {
            id: "spouse-request-declined",
            title: "Spouse Request Declined",
            color: redStatus,
            isError: true,
            hideFromSelector: true,
        },
        {
            id: "spouse-request-accepted",
            title: "Spouse Request Accepted",
            color: purpleStatus,
            isError: false,
            hideFromSelector: true,
        },
        {
            id: "awaiting-mortgage",
            title: "Awaiting Mortgage",
            color: greenStatus,
            isError: true,
        },
        {
            id: "paye-registration-questionnaire",
            title: "PAYE Registration - Questionnaire",
            color: greenStatus,
            isError: false,
            hideFromSelector: true,
        },
        {
            id: "other",
            title: "Other",
            color: greenStatus,
            isError: true,
        },
    ],
};

export const GROUPS_DICT = {
    "new-clients": "New Clients",
    "new-submissions": "New Submissions",
    "ready-for-review": "Ready For Review",
    "submitted-to-revenue": "Submitted To Revenue",
    "payments": "Payments",
    "id-verification": "ID Verification",
    "archived": "Archived",
    "on-hold": "On Hold",
};

export const ALL_STATUSES = Object.entries(GROUPED_STATUSES)
    .flatMap(([mainStatusId, statuses]) => statuses.map(status => ({ ...status, mainStatusId })));

export const ERROR_STATUSES = ALL_STATUSES.filter(status => status.isError);

export const POSSIBLE_STATUSES = Object
    .entries(GROUPED_STATUSES)
    .flatMap(
        ([_groupId, statuses]) => statuses.map(status => status.id)
    );

export const STATUSES_DICT = convertGroupedArraysOfObjectsToDict(POSSIBLE_STATUSES, GROUPED_STATUSES);

export const MAIN_STATUSES = [{
    id: "all",
    title: "All",
}, {
    id: "new-clients",
    title: "New Clients",
}, {
    id: "new-submissions",
    title: "New Submissions",
}, {
    id: "ready-for-review",
    title: "Ready For Review",
}, {
    id: "submitted-to-revenue",
    title: "Submitted To Revenue",
}, {
    id: "payments",
    title: "Payments",
}, {
    id: "id-verification",
    title: "ID Verification",
}, {
    id: "archived",
    title: "Archived",
}, {
    id: "on-hold",
    title: "On Hold",
}] as const;

export const POSSIBLE_MAIN_STATUSES = MAIN_STATUSES.map(status => status.id);
export const POSSIBLE_ERROR_STATUSES = GROUPED_STATUSES["on-hold"].map(status => status.id);

export const SUBMISSION_METHODS = [{
    id: "n/a",
    title: "N/A",
}, {
    id: "manual",
    title: "Manual",
}, {
    id: "automate",
    title: "Automate",
}];

export const POSSIBLE_SUBMISSION_METHODS = SUBMISSION_METHODS.map(method => method.id);

export const PRELIMINARY_RESULT_STATES = [
    "balanced",
    "underpayment",
    "overpayment",
] as const;

export const GROUPED_SUB_STATUSES: IStatusGroups = {
    "mortgage": [
        {
            id: "eligible",
            title: "Mortgage",
            color: purpleStatus,
            isError: false,
        },
        {
            id: "not-eligible",
            title: "-",
            color: greyStatus,
            isError: false,
        },
    ],
};

export const POSSIBLE_SUB_STATUSES = Object
    .entries(GROUPED_SUB_STATUSES)
    .flatMap(([groupId]) => groupId);

export const RECEIPTS_TABS: {
  id: string;
  name: string;
  required: { name: string }[] | ((_names: string[]) => ({ name: string, forAdminOnly?: boolean }[]));
}[] = [{
    id: "spcc1",
    name: "Single Parent",
    required: (names: string[]) => [
        ...names.map(name => ({ name: `SPCC1 Form ${name}` })),
        { name: "Completed SPCC1 form", forAdminOnly: true },
    ],
}, {
    id: "workingFromHome",
    name: "Work from Home",
    required: [],
}, {
    id: "carer",
    name: "Career",
    required: [],
}, {
    id: "medical",
    name: "Medical",
    required: [],
}, {
    id: "nursingHome",
    name: "Nursing Home",
    required: [],
}, {
    id: "thirdLevelEducation",
    name: "Third Level Education",
    required: [],
}];

export enum SCRAPED_ROWS {
    ANNUAL_SALARY = "annualSalary",
    ANNUAL_PENSION_CONTRIBUTION = "annualPensionContribution",
    DSP_PAYMENTS = "DSPPayments",
    ADJUSTMENTS = "Adjustments",
    TAXABLE_INCOME = "taxableIncome",
    INCOME_TAX_PAID = "incomeTaxPaid",
    INCOME_TAX_20 = "incomeTax20",
    INCOME_TAX_40 = "incomeTax40",
    GROSS_INCOME_TAX_LIABILITY = "grossIncomeTaxLiability",
    PERSONAL_TAX_CREDIT = "personalTaxCredit",
    EMPLOYEE_TAX_CREDIT = "EmployeeTaxCredit",
    NET_INCOME_TAX_LIABILITY = "netIncomeTaxLiability",
    PAY_FOR_USC = "payForUSC",
    USC_PAID = "USCPaid",
    USC_DUE = "USCDue",
    PRSI = "PRSI",
    TOTAL_DEDUCTIONS = "totalDeductions",
    INCOME_TAX_DIFFERENCE = "incomeTaxDifference",
    USC_DIFFERENCE = "USCDifference",
    BALANCE = "Balance",
}
